<template>
  <el-dialog 
    title="设置"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
    close="setting"
  >
    <gl-wrap right="200px">
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini" v-loading="loading" :rules="rules">
        <!-- verify -->
        <el-form-item prop="discount" label="折扣(%)" required >
          <el-input v-model.number="formData.discount" placeholder="请输入折扣" :maxlength="3">
            <template #append>
              <span>%</span>
            </template>
          </el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {goodsById, setDiscount} from '@/api/backendall/goods'
export default {
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    const validateDiscount = (rule, value, callback) => {
      // const newValue = value?.trim()
      // if(!value && !(value?.trim())){
      if(!value){
        callback(new Error('该项不能为空'))
      }else if(isNaN(value)){
        callback(new Error('只允许输入数字'))
      }else if(rule.isInteger && !Number.isInteger(+value)){
        callback(new Error('只允许输入整数'))
      }
      else if(value < 0 || value > 100){
        callback(new Error('数字区间在1~100'))
      }
      else{
        callback()
      }
    }
    return{
      formData: {},
      loading: false,
      rules: {
        discount: [
          { required: true, message: '该项不能为空', trigger: 'blur' },
          { 
            type: 'number',
            message: '请输入数值', 
            trigger: 'change', 
            // validator:  validateDiscount,
          },
        ],
      },
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('valid:', valid)
        this.loading = true
        try{
          const params = {
            itemId: this.itemId,
            discount: this.formData.discount,
          }
          const res = await setDiscount(params)
          this.loading = false
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('reload')
        }catch(e){
          this.loading = false
        }
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  }
}
</script>

<style lang="scss">
.setting{
  // .el-input-group__append, 
  // .el-input-group__prepend{
  //   padding: 0 5px !important;
  // }
  .el-input-group__append{
    padding: 0 5px !important;
  }
}
</style>