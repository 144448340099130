<template>
  <el-dialog 
    title="审核"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="approval" label="审核" required verify>
          <el-radio-group v-model="formData.approval">
            <el-radio v-for="(item,index) of statusEnum" :key="index" :label="item.value">{{item.name}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="remark" label="不通过原因" verify v-if="formData.approval == '2'">
          <el-input v-model="formData.remark"></el-input>
        </el-form-item>
        <el-form-item prop="isFreeFreight" label="是否包邮" verify>
          <gl-option v-model="formData.isFreeFreight" :list="freeFreightList"></gl-option>
          <div class="custom-form-hint">
            <div class="desc">如果设置包邮，支付时不收取运费，退款时扣除所设置的运费。</div>
            <div class="desc">如果设置不包邮，支付时收取运费，退款时不扣除运费。</div>
          </div>
        </el-form-item>
        <el-form-item prop="freight" label="运费" verify v-if="formData.isFreeFreight == '1'">
          <el-input v-model="formData.freight"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex style="margin-top: 50px;">
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {goodsAudit} from '@/api/backendall/goods'
export default {
  name: 'audit',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      formData: {
        itemId: this.itemId,
        approval: '',
        remark: '',
      },
      loading: false,
      statusEnum: [
        {name: '通过', value: '1'},
        {name: '不通过', value: '2'},
      ],
      freeFreightList: [
        {name: '是', value: '1'},
        {name: '否', value: '0'},
      ],
    }
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.formData.itemId = this.itemId
        this.loading = true
        const params = {
          ...this.formData,
          isFreeFreight: this.formData.isFreeFreight !=null ? Number(this.formData.isFreeFreight) : this.formData.isFreeFreight
        }
        const res = await goodsAudit(params)
        this.loading = false
        this.handleClose()
        this.$message.success('操作成功')
        this.$emit('reload')
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.custom-form-hint{
  .desc{
    font-size: 12px;
    color: #aaa;
    line-height: 18px !important;
  }
}
</style>