<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <!-- <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="onClickAction('addVisible')"> 新增 </el-button>
    </div> -->
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="itemId" label="商品ID">
          <gl-list-search
            v-model.trim="mFormData.itemId"
            placeholder="商品ID"
            @enter="mGetList"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="itemName" label="商品名称">
          <gl-list-search
            v-model.trim="mFormData.itemName"
            placeholder="商品名称"
            @enter="mGetList"
          ></gl-list-search>
        </el-form-item>
        <el-form-item prop="itemStatus" label="商品状态">
          <gl-option
            v-model="mFormData.itemStatus"
            :list="publishStatus"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="approval" label="审批状态">
          <gl-option
            v-model="mFormData.approval"
            :list="auditStatusList"
          ></gl-option>
        </el-form-item>
        <el-form-item prop="startTime" label="上架时间">
          <!-- v-model="mFormData.dateTime"  -->
          <gl-date-picker
            :ref="mDateRef"
            :startTime.sync="mFormData.listtingTimeStart"
            :endTime.sync="mFormData.listtingTimeEnd"
          >
          </gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="itemId" label="商品ID"></el-table-column>
        <el-table-column prop="itemName" label="商品名称"></el-table-column>
        <el-table-column prop="pictureUrl" label="商品主图">
          <template slot-scope="scope">
            <el-image
              style="width: 60px; height: 60px"
              :src="scope.row.pictureUrl"
              fit="cover"
              :preview-src-list="[scope.row.pictureUrl]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="listtingTime" label="上架时间"></el-table-column>
        <el-table-column prop="discount" label="折扣">
          <template slot-scope="{ row }">
            <span v-show="row.discount"> {{ row.discount }}% </span>
          </template>
        </el-table-column>
        <el-table-column prop="itemStatus" label="上下架">
          <template slot-scope="{ row }">
            <span v-if="row.itemStatus == 1"> 上架 </span>
            <span v-if="row.itemStatus == 2"> 下架 </span>
            <span v-if="row.itemStatus == 3"> 售罄 </span>
            <span v-if="row.itemStatus == 4"> 删除 </span>
          </template>
        </el-table-column>
        <el-table-column prop="approval" label="审核状态">
          <template slot-scope="{ row }">
            <span v-if="row.approval == 1"> 审批通过 </span>
            <span v-if="row.approval == 2"> 审批不通过 </span>
            <span v-if="row.approval == 3"> 待审批 </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210px" fixed="right">
          <template slot-scope="{ row }">
            <el-button v-if="row.approval == 3" v-check="'103031'" type="text" @click="onClickAction('auditVisible', row)"> 审核 </el-button>
            <el-button v-if="row.itemStatus !== 1" v-check="'103032'" type="text" @click=" $router.push({ path: 'update', query: { itemId: row.itemId, title: '修改' },})"> 修改 </el-button>
            <el-button v-if="row.approval !== 3" v-check="'103033'" type="text" @click="mPublish(row.itemId)" > 上/下架 </el-button>
            <!-- <el-button v-if="row.itemStatus == 2 && row.approval !== 3" type="text" @click="mPublish(row.itemId)" > 上架 </el-button>
            <el-button v-if="row.itemStatus == 1 && row.approval !== 3" type="text" @click="mRevoke(row.itemId)"> 下架 </el-button> -->

            <el-button type="text" @click="onClickAction('settingVisible', row)" > 设置折扣 </el-button>
            <el-button type="text" @click="onClickAction('activityVisible', row)"> 设置活动 </el-button>
            <el-button type="text" @click="onClickAction('freightVisible', row)"> 设置运费 </el-button>
            <el-button type="text" @click="$router.push({ path: 'detail', query: { itemId: row.itemId } })"> 查看 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </gl-card>
    <audit :visible.sync="auditVisible" :itemId="itemId" @reload="mGetList" />
    <setting
      :visible.sync="settingVisible"
      :itemId="itemId"
      @reload="mGetList"
    />
    <activityDialog
      :visible.sync="activityVisible"
      :itemId="itemId"
      @reload="mGetList"
    />
    <freightSeting 
      :visible.sync="freightVisible"
      :itemId="itemId"
      @reload="mGetList"
    />
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import { goodsList, publishAndRevoke } from '@/api/backendall/goods'
import audit from './audit.vue'
import setting from './setting.vue'
import activityDialog from './activityDialog.vue'
import freightSeting from './freightSeting.vue'

export default {
  name: 'goodsListIndex',
  components: { audit, setting, activityDialog, freightSeting},
  mixins: [ListMixin],
  data() {
    return {
      publishStatus: [
        { name: '上架', value: '1' },
        { name: '下架', value: '2' },
        { name: '售罄', value: '3' },
        { name: '删除', value: '4' },
      ],
      auditStatusList: [
        { name: '审批通过', value: '1' },
        { name: '审批不通过', value: '2' },
        { name: '待审批', value: '3' },
      ],
      auditVisible: false,
      itemId: '',
      settingVisible: false,  //设置折扣
      activityVisible: false, // 设置活动
      freightVisible: false, // 设置运费
      predictType: ['settingVisible','activityVisible'],
    }
  },
  mounted() {
    this.mGetListFun = goodsList
    this.mPublishFun = publishAndRevoke
    // this.mPublishFun = goodsPublish
    // this.mRevokeFun = goodsRevoke
    this.mGetList()
  },
  activated(){
    window.scrollTo(0,1)
    if(this.mPageNum > 0){
      this.mGetList()
    }
  },
  // activated(){
  //   if (!this.$route.meta.canKeep) {
  //     // 刷新数据
  //     this.mGetList()
  //     // 重置页面位置
  //     window.scrollTo(0,1)
  //   }
  // },
  methods: {
    onClickAction(name, item) {
      if(this.predictType.includes(name) && item.isPresale){
        return this.$message.warning('预售商品不能设置折扣/活动')
      }
      this.itemId = item?.itemId || ''
      this[name] = true
    },
  },
}
</script>

<style>

</style>
