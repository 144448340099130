import request from '@/plugins/request'

// 查询商品SKU
export function getGoodsSKU(itemId = ''){
  return request({
    url: '/api/goods/item/getItem',
    method: 'GET',
    params: {itemId},
  })
}

//设置天天特价
export function setSale(data = {}){
  return request({
    url: 'api/goods/item/updateItem',
    method: 'POST',
    data,
  })
}