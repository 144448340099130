<template>
  <el-dialog 
    title="设置运费"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
    close="setting"
  >
    <gl-wrap right="200px">
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini" v-loading="loading">
        <el-form-item prop="isFreeFreight" label="是否包邮" verify>
          <gl-option v-model="formData.isFreeFreight" :list="freightOption"></gl-option>
        </el-form-item>
        <el-form-item prop="freight" label="运费" required verify>
          <el-input v-model.number="formData.freight" placeholder="请输入运费" :maxlength="5"></el-input>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {goodsById, setFreight} from '@/api/backendall/goods'
export default {
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      freightOption: [
        {name: '是', value: '1'},
        {name: '否', value: '0'},
      ],
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getFreight()
      }
    },
  },
  methods: {
    onSubmit(){
      this.$refs.form.validate(async valid => {
        console.log('valid:', valid)
        this.loading = true
        try{
          const params = {
            itemId: this.itemId,
            isFreeFreight: Number(this.formData.isFreeFreight),
            freight: this.formData.freight,
          }
          const res = await setFreight(params)
          this.loading = false
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('reload')
        }catch(e){
          this.loading = false
        }
      })
    },
    async getFreight(){
      const res = await goodsById(this.itemId)
      this.formData = {
        isFreeFreight: res.data.isFreeFreight ? res.data.isFreeFreight.toString() : res.data.isFreeFreight,
        freight: res.data.freight,
      }
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  }
}
</script>

<style lang="scss">
.setting{
  // .el-input-group__append, 
  // .el-input-group__prepend{
  //   padding: 0 5px !important;
  // }
  .el-input-group__append{
    padding: 0 5px !important;
  }
}
</style>