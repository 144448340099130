<template>
  <el-dialog 
    title="设置活动"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="650px"
    close="setting"
  >
    <gl-wrap>
      <el-form :model="formData" ref="form" label-width="130px" width="500px" size="mini" v-loading="loading">
        <el-form-item prop="activityType" label="活动状态" required verify>
          <gl-option v-model="formData.activityType" :list="activityTypeList"></gl-option>
        </el-form-item>
        <el-form-item prop="activityIsOpen" label="活动是否开启" required verify>
          <gl-option v-model="formData.activityIsOpen" :list="saleOption"></gl-option>
        </el-form-item>
        <el-form-item prop="specialDate" label="活动结束日期" verify v-if="formData.activityType == 1">
          <el-date-picker
            v-model="formData.specialDate"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-table :data="formData.goodsSkuVOList" style="width: 100%" v-if="formData.activityType">
          <el-table-column prop="skuId" label="规格ID"></el-table-column>
          <!-- <el-table-column prop="skuName" label="商品规格"></el-table-column>
          <el-table-column prop="costPrice" label="出厂价"></el-table-column>
          <el-table-column prop="retailPrice" label="预售价"></el-table-column> -->
          <template v-if="formData.activityType == 1">
            <el-table-column prop="specialInventory" label="天天特价活动库存">
              <template slot-scope="{row}">
                <el-input v-model="row.specialInventory" placeholder="天天特价活动库存" size="mini" :maxlength="10"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="specialPrice" label="天天特价活动单价">
              <template slot-scope="{row}">
                <el-input v-model="row.specialPrice" placeholder="天天特价活动单价" size="mini" :maxlength="10"></el-input>
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column prop="halfPrice" label="新人半价活动单价">
              <template slot-scope="{row}">
                <el-input v-model="row.halfPrice" placeholder="新人半价活动单价" size="mini" :maxlength="10"></el-input>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { getGoodsSKU, setSale } from '@/api/backendall/setActivity'
export default {
  name: 'ActivityDialog',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getGoodsSKU(this.itemId)
      },
      deep: true,
      immediate: true,
    },
  },
  data(){
    return{
      loading: false,
      formData: {
        goodsSkuVOList: [],
      },
      activityTypeList: [
        {name: '天天特价', value: '1'},
        {name: '新人半价', value: '2'},
      ],
      saleOption: [
        {name: '开启', value: '1'},
        {name: '关闭', value: '0'},
      ],
    }
  },
  methods: {
    onSubmit(){
      if(this.formData.goodsSkuVOList.length){
        for(let i=0; i < this.formData.goodsSkuVOList.length; i++){
          if(!this.formData.goodsSkuVOList[i].specialInventory && this.formData.activityType == 1){  //活动库存
            return this.$message({type: 'warning', message: `请输入 ${this.formData.goodsSkuVOList[i].skuName} 天天特价活动库存`})
          }
          if(!this.formData.goodsSkuVOList[i].specialPrice && this.formData.activityType == 1){  //活动价格
            return this.$message({type: 'warning', message: `请输入${this.formData.goodsSkuVOList[i].skuName} 天天特价活动单价`})
          }

          if(!this.formData.goodsSkuVOList[i].halfPrice && this.formData.activityType == 2){
            return this.$message({type: 'warning', message: `请输入${this.formData.goodsSkuVOList[i].skuName} 新人半价活动单价`})
          }
        }
      }
      
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        this.loading = true
        try{
          const params = {
            ...this.formData,
            itemId: this.itemId,
            isSpecial: Number(this.formData.isSpecial),
            
          }
          await setSale(params)
          this.loading = false
          this.handleClose()
          this.$message.success('操作成功')
          this.$emit('reload')
        }catch(e){
          this.loading = false
        }
      })
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
    async getGoodsSKU(){
      const res = await getGoodsSKU(this.itemId)
      const formData = {
        activityType : res.data.activityType != null ? res.data.activityType.toString() : res.data.activityType,
        activityIsOpen : res.data.activityIsOpen != null ? res.data.activityIsOpen.toString() : res.data.activityIsOpen,
        specialDate: res.data.specialDate,
        goodsSkuVOList: res.data.goodsSkuVOList,
      }
      this.formData = formData
    },
  },
}
</script>

<style>

</style>